// TwitterContainer.js
import React from "react"
import { motion, AnimatePresence } from "framer-motion"
import { OutboundLink } from "gatsby-plugin-google-gtag"


const TweetBox = props => {
  const { tweetMarkup, tweetId ,tweetAuthor} = props

  return (
    <AnimatePresence>
      <motion.div
        className="tweet-detail"
        initial={{ opacity: 0, y: 60 }}
        animate={{
          opacity: 1,
          y: 0,
          transition: { stiffness: 300, delayChildren: 0.5 },
        }}
        exit={{ opacity: 0, y: 60 }}
      >
        <div dangerouslySetInnerHTML={{ __html: tweetMarkup }} />
        <div className="util-bar">
          <OutboundLink  rel="nofollow" target="_blank" href={`https://twitter.com/intent/follow?screen_name=${tweetAuthor}`}>
            Follow @{tweetAuthor}
          </OutboundLink>
          <OutboundLink  rel="nofollow" target="_blank" href={`https://twitter.com/intent/retweet?tweet_id=${tweetId}&hide_thread=true`}>
            Retweet
          </OutboundLink>
        </div>
      </motion.div>
    </AnimatePresence>
  )
}

export default TweetBox
