import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import TweetBox from "../components/TweetBox"
import SEO from "../components/SEO" 
import styled from "styled-components"
import contrast from "../utils/contrast"

const getRandomNum = arr => {
  return Math.floor(Math.random() * Math.floor(arr.length))
}

export default function Home({ data }) {
  const colors = [
    "#FBCD58",
    "#58A586",
    "#8B71C3",
    "#EDA4AD",
    "#065F92",
    "#630705",
    "#444444",
    "#0A7416",
    "#48AF97",
    "#92B7D4"
  ]
  const tweetCollection = data.allAirtable.edges.filter( t => { return t.node.fields.tweetpayload.author_name !== null  })

  const [isLoaded, setLoadState] = useState(false)
  const [activeTweet, setTweet] = useState({
    content: getRandomNum(tweetCollection),
    bgcolor: getRandomNum(colors),
    tweetCount: 1
  })

  useEffect(() => {
    setLoadState(prevState => {
      return !prevState
    })
    
   gtag('event', 'Load Tweet', { 'count': activeTweet.tweetCount });
  }, [activeTweet])

  function newTweet() {
    setLoadState(prevState => {
      return !prevState
    })
    setTweet( prevState => {
 
      return {
        content: getRandomNum(tweetCollection),
        bgcolor: getRandomNum(colors),
        tweetCount: prevState.tweetCount + 1
      }
    })
  }
  const tweet = tweetCollection[activeTweet.content].node
  const tweetMarkup = tweet.fields.tweetpayload.html
  const tweetAuthor  = tweet.fields.tweetpayload.author_url.replace('https://twitter.com/','');
  const tweetId =  tweet.fields.tweetpayload.url.split(/\/status\//)[1];
  const activeColor = colors[activeTweet.bgcolor];
  const textColorClass = contrast(activeColor); 
  const shareText = `A collection of timeless takes on entrepreneurism from Twitter curated by @stanwilsonjr`
  const shareLink =`http://twitter.com/share?text=${shareText}&hashtags=TweetKnowledge&url=https://tweet-knowledge.com/`

  const TweetContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: center;
    background-color: ${activeColor};
    &.light-text{
      color: #fff;
    }
    &.dark-text{
      color: #444;
    }

    & .header {
      padding: 20px;
      flex-shrink: 0;
    }
  `
  return (
    <>
      <TweetContainer className={textColorClass}>
        <SEO />
        <header>
          <h1>Tweet Knowledge</h1>
          <p>
            A collection of timeless takes on entrepreneurism from
            Twitter curated by <OutboundLink rel="nofollow" target="_blank"  href="http://www.twitter.com/stanwilsonjr">Stan Wilson Jr</OutboundLink>
          </p>
          <OutboundLink rel="nofollow" target="_blank" href={shareLink} title="Share via Twitter"  className="twitter-share"> Share</OutboundLink>
        </header>
        {isLoaded && <TweetBox newTweet={newTweet} tweetMarkup={tweetMarkup} tweetId={tweetId} tweetAuthor={tweetAuthor} />}
        <div className="header">
          <button onClick={newTweet}>Load a new Tweet</button>
        </div>
      </TweetContainer>
    </>
  )
}

export const tweetQuery = graphql`
  query TweetQuery {
    allAirtable {
      edges {
        node {
          id
          data {
            Handle
            Name
            Tweet
          }
          fields {
            tweetpayload {
              html
              url
              author_name
              author_url
            }
          }
        }
      }
    }
  }
`
