
let convertToRGB = newColor => {
  let r, g, b
  if (newColor.match(/^rgb/) && newColor.match(/^rgb/).length > 0) {
    // handle rgb
    console.log(newColor)
  } else {
    // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
    if (newColor.length === 3) {
      newColor = newColor.replace(/(.)/g, "$1$1")
    }
    r = parseInt(newColor.substr(0, 2), 16)
    g = parseInt(newColor.substr(2, 2), 16)
    b = parseInt(newColor.substr(4, 2), 16)
  }
  let luma = 0.2126 * r + 0.7152 * g + 0.0722 * b // per ITU-R BT.709
  let newRGB = `rgb(${r},${g},${b})`
  return { rgb: newRGB, luma: luma }
}

function contrast(color) {
  let newHex = color.replace(/#/g, "")

  if (newHex.length === 3 || newHex.length === 6) {
    let newRGB = convertToRGB(newHex)
    return (newRGB.luma < 150)? "light-text" : "dark-text";
  }
}

export default contrast
